<template>
  <!-- <transition name="fade" mode="out-in"> -->

  <div class="screen-wrap">
    <ErrorDialog
      v-if="error"
      :error="error"
      :message="errorMessage"
      @exitError="extiError"
    />
    <div class="d-flex flex-column pa-5">
      <div class="d-flex justify-space-between">
        <div class="text-h6">
          {{ $t("string.chapter") }} : {{ question.chapter_name }}
        </div>
      </div>
      <div>{{ $t("string.question") }} : {{ question.name }}</div>
      <div class="d-flex justify-center">
        <AbstractAvatar
          :avatar="auth.Player.avatar"
          :height="150"
          :width="150"
          class=""
        ></AbstractAvatar>
      </div>
      <div class="d-flex justify-center ma-3 mx-auto">
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 6 : 2"
            class="ma-2 d-flex align-center justify-center"
            width="100"
            height="80"
            :color="difficulty == 1 ? 'yellow lighten-4' : 'grey lighten-4'"
            :class="{ 'on-hover': hover }"
            @click="difficulty = 1"
          >
            <span>
              <div class="text-cption font-weight-medium">
                {{ $t("string.easy") }}
              </div>
              <!-- <div>+50</div> -->
              <div class="text-h4 text-center text--primary mb-3 pt-3">
                <v-row align="center" justify="center">
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                </v-row>
              </div>
              <div class="text-center text-caption">+ 0</div>
            </span>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 6 : 2"
            class="ma-2 d-flex align-center justify-center"
            width="100"
            height="80"
            :color="difficulty == 2 ? 'yellow lighten-4' : 'grey lighten-4'"
            :class="{ 'on-hover': hover }"
            @click="difficulty = 2"
          >
            <span>
              <div class="text-cption font-weight-medium">
                {{ $t("string.medium") }}
              </div>
              <!-- <div>+100</div> -->
              <div class="text-h4 text-center text--primary mb-3 pt-3">
                <v-row align="center" justify="center">
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                </v-row>
              </div>
              <div class="text-center text-caption">+ 50</div>
            </span>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 6 : 2"
            class="ma-2 d-flex align-center justify-center"
            width="100"
            height="80"
            :color="difficulty == 3 ? 'yellow lighten-4' : 'grey lighten-4'"
            :class="{ 'on-hover': hover }"
            @click="difficulty = 3"
          >
            <span>
              <div class="text-cption font-weight-medium">
                {{ $t("string.hard") }}
              </div>
              <!-- <div>+150</div> -->
              <div class="text-h4 text-center text--primary mb-3 pt-3">
                <v-row align="center" justify="center">
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                  <v-img
                    max-width="15"
                    max-height="25"
                    class="mt-2"
                    :src="require('@/assets/bugCatcher/DifficultyFire.png')"
                  ></v-img>
                </v-row>
              </div>
              <div class="text-center text-caption">+ 100</div>
            </span>
          </v-card>
        </v-hover>
      </div>
      <v-btn class="text-h6" block large @click="play()" id="StartGame">
        {{ $t("action.start") }}
      </v-btn>
    </div>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    boss: (state) => state.boss.data,
    event: (state) => state.event.data,
    tutorial: (state) => state.tutorial.data,
    settings: (state) => state.settings.data,
  }),
  props: ["tile", "ladder", "callbackStart"],
  data: () => ({
    schoolYear: null,
    intraction: null,
    error: false,
    errorMessage: null,
    boss_data: null,
    EventApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    QuestionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    gameApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    difficulty: 1,
    level: {},
    question: {},
    event_data: {},
    students: [],
    student_temp: [],
  }),
  created() {
    this.QuestionApi.url =
      this.$api.servers.question +
      "/v2/chapters/" +
      this.tile.chapterKey +
      "/questions?lang=" +
      this.$_getLocale();

    this.QuestionApi.callbackReset = () => {
      this.QuestionApi.isLoading = true;
      this.QuestionApi.isError = false;
    };

    this.QuestionApi.callbackError = (e) => {
      this.QuestionApi.isLoading = false;
      this.QuestionApi.isError = true;
      this.QuestionApi.error = e;
    };
    this.QuestionApi.callbackSuccess = (resp) => {
      this.question = resp.find(
        (element) => element.key == this.tile.questionKey
      );
      this.QuestionApi.isLoading = false;
    };
    this.QuestionApi.method = "GET";

    this.QuestionApi.params = { lang: this.$_getLocale() };
    this.$api.fetch(this.QuestionApi);
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else if (this.auth.Player.userType == "adult") {
      this.schoolYear = 1;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }
    this.$api.fetch(this.EventApi);
    this.boss_data = this.boss.find(
      (element) => element.level_id == this.$route.params.id
    );
    if (this.tutorial.Ongoing == "tutExploreLevel") {
      this.intraction = "pointer-events: none;";
      setTimeout(() => {
        this.$intro().exit();
        this.$intro()
          .setOptions({
            exitOnOverlayClick: false,
            showButtons: false,
            showBullets: false,
            scrollToElement: false,
            steps: [
              {
                element: document.querySelector("#StartGame"),
                intro:
                  '<img src="' +
                  require("@/assets/tutorial/chat_1.png") +
                  '">' +
                  this.$t("tutorial.exploreLevel.dialog_4"),
              },
            ],
          })
          .start();
        this.intraction = "pointer-events: all;";
        let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
        audio.volume = this.settings.audio.sfx * this.settings.audio.master;
        audio.play();
      }, 1000);
    }
  },
  methods: {
    extiError() {
      this.error = false;
    },
    async getAvatars() {
      for (let i = 0; i < this.student_temp.length; i++) {
        await this.gameApiCall(this.student_temp[i]);
      }
    },
    async gameApiCall(element) {
      this.gameApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/player/" +
        element.mochiId;

      this.gameApi.callbackReset = () => {
        this.gameApi.isLoading = true;
        this.gameApi.isError = false;
      };

      this.gameApi.callbackError = (e) => {
        this.gameApi.isLoading = false;
        this.gameApi.isError = true;
        this.gameApi.error = e;
      };
      this.gameApi.callbackSuccess = (resp) => {
        this.gameApi.isLoading = false;
        element.name = resp.name;
        element.avatar = resp.avatar;
        this.students.push(element);
      };
      this.gameApi.method = "GET";
      await this.$api.fetch(this.gameApi);
    },
    updateEvent() {
      this.event_data = this.$_.cloneDeep(this.event);
      this.event_data["record"] = {
        levelId: this.$route.params.id,
        topStar: this.level.EventRecord ? this.level.EventRecord.topStar : 0,
        topScore: this.level.EventRecord ? this.level.EventRecord.topScore : 0,
        attempt: this.level.EventRecord ? this.level.EventRecord.attempt : 0,
        submission: this.level.EventRecord
          ? this.level.EventRecord.submission
          : 0,
      };

      this.event_data["boss"] = {
        key: this.boss_data.boss_key,
        inventory: this.boss_data.inventory,
        img: this.boss_data.boss_img,
        name: this.boss_data.boss_name,
        isCollected: false,
      };

      this.event_data["api"] = {
        submission: true,
        record: true,
        player: true,
        inventory: true,
      };

      this.event_data["submission"] = {
        institutionId: this.auth.Institution ? this.auth.Institution.id : null,
        classroomId: this.auth.Classroom ? this.auth.Classroom.id : null,
        studentId: this.auth.Classroom ? this.auth.User.id : null,
        levelId: this.$route.params.id,
        eventId: this.boss_data.event_id,
        mochiId: this.auth.Player.mochiId,
        syllabusKey: this.level.Level.chapterKey.split("_")[0],
        chapterKey: this.level.Level.chapterKey,
        questionKey: this.level.Level.questionKey,
        countQuestion: 10,
        countCorrect: 0,
        accuracy: 0,
        time: 0,
        star: 0,
        score: 0,
        submission: this.level.EventRecord
          ? this.level.EventRecord.submission
          : 0,
      };

      this.$store.commit("updateEvent", this.event_data);
    },
    play() {
      let tempSettings = this.$_.cloneDeep(this.settings);
      tempSettings.cache = true;
      this.$store.commit("updateSettings", tempSettings);
        this.callbackStart();
    },
    exit() {
      this.$router.push({
        name: "PageEventLevels",
      });
    },
    updateTutorial() {
      this.tutorialApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/tutorial/complete";
      this.tutorialApi.callbackReset = () => {
        this.tutorialApi.isLoading = true;
        this.tutorialApi.isError = false;
        this.tutorialApi.error = null;
      };
      this.tutorialApi.callbackError = (e) => {
        this.tutorialApi.isLoading = false;
        this.tutorialApi.isError = true;
        this.tutorialApi.error = e;
      };
      this.tutorialApi.callbackSuccess = (resp) => {
        this.tutorialApi.isLoading = false;
        this.$store.commit("updateTutorial", resp);
      };
      if (this.tutorial.Ongoing == "tutExploreLevel") {
        this.tutorialApi.params = {
          key: "tutExploreLevel",
        };
      }
      this.$api.fetch(this.tutorialApi);
    },
  },
};
</script>

    <style scoped>
.list-panel {
  margin-top: 20px;
  padding: 5px 0;
}

.list-card {
  padding: 0 10px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>